<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->

        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(update_time)="data">
          {{toTime(data.item.update_time)}}
        </template>

        <template #cell(updator)="data">
          {{getCodeLabel('user', data.item.updator)}}
        </template>
        <!-- Column: State -->

        <template #cell(content_old)="data">
          {{ data.item.content_old.substring(0,10) }}
          <feather-icon
                  icon="TwitchIcon"
                  size="21"
                  variant="success"
                  :id="`content_old-${data.item.id}`"
          />
            <b-tooltip
                    :target="`content_old-${data.item.id}`"
                    placement="top"
                    extraCssText="width:300px"
            >
              <span v-html="data.item.content_old.replace(/(\r\n|\n|\r)/gm, '<br />')"></span>
            </b-tooltip>
        </template>


        <template #cell(content_new)="data">
          {{ data.item.content_new.substring(0,10) }}
          <feather-icon
                  icon="TwitchIcon"
                  size="21"
                  variant="success"
                  :id="`content_new-${data.item.id}`"
          />
          <b-tooltip
                  :target="`content_new-${data.item.id}`"
                  placement="top"
          >
            <span v-html="data.item.content_new.replace(/(\r\n|\n|\r)/gm, '<br />')"></span>
          </b-tooltip>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
<!--单菜单-->
          <b-link>
            <feather-icon @click="delOrderItem(data.item)"
                icon="XSquareIcon"
                size="16"
                class="align-middle text-body"
            />
          </b-link>
<!--多菜单-->
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-bomchangelog-view', query: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-bomchangelog-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,commendContent } from '@core/utils/filter'
import bomchangelogUseList from './bomchangelogUseList'
import bomchangelogStore from './bomchangelogStore'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  methods: {

  },
  props: {
    bomId: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    // Register module
    if (!store.hasModule('bomchangelog')) store.registerModule('bomchangelog', bomchangelogStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('bomchangelog')) store.unregisterModule('bomchangelog')
    })

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = bomchangelogUseList(
            {
              bomId:props.bomId
            }
    )

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime, 
     toDate,
      commendContent
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
